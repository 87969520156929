<template>
    <div>
        <b-button
            class="ml-auto"
            variant="primary"
            :disabled="compIsBusy || isBusyDataImport || compDataImportIsRunning"
            v-b-modal.data-import-repeat
        >
            Daten-Import wiederholen
            <b-spinner v-if="isBusyDataImport" small />
        </b-button>
        <b-modal
            id="data-import-repeat"
            title="Daten-Import wiederholen"
            cancel-title="Abbrechen"
            ok-title="Daten-Import wiederholen"
            @ok="onClickDataImportRepeatOk"
            :ok-disabled="!validationAllowed"
        >
            Soll der Daten-Import wirklich wiederholt werden?
            <div class="font-italic">
                <font-awesome-icon :icon="['fas', 'info-circle']" /> Ein erneuter Daten-Import sollte erst nach einer
                Korrektur der Daten durch das esolva Backoffice erfolgen.
            </div>
            <div class="mt-2 font-italic">
                esolva Backoffice: <PhoneCall :phone-number="phoneNumberEsolvaBackoffice" />
            </div>
        </b-modal>
    </div>
</template>

<script>
import PhoneCall from "@/components/general/PhoneCall.vue";
import auftragsAPI from "@/services/api/auftrags.api";

export default {
    name: "BtnDataImportIse",
    components: { PhoneCall },
    props: {
        auftrag: Object,
        isBusy: {
            type: Boolean,
            default: false,
        },
        phoneNumberEsolvaBackoffice: String,
        validationAllowed: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isBusyDataImport: false,
        };
    },
    computed: {
        compIsBusy() {
            return this.isBusy;
        },
        compDataImportIsRunning() {
            return this.auftrag?.auftragSyncQueue?.status?.toUpperCase() === "RUNNING";
        },
    },
    methods: {
        onClickDataImportRepeatOk() {
            this.isBusyDataImport = true;
            auftragsAPI
                .dataImportStart(this.auftrag.auftragID)
                .then(() => {
                    this.$bvToast.toast(`Der Daten-Import wurde gestartet.`, {
                        title: "INFO",
                        variant: "info",
                        toaster: "b-toaster-bottom-right",
                        appendToast: true,
                    });
                    this.pollingStart();
                })
                .catch(() => {
                    // this is on purpuse to re-enable the button in case of error
                    this.isBusyDataImport = false;
                });
        },
        pollData() {
            this.$emit("poll-data", true);
            if (["ERROR"].includes(this.auftrag?.auftragSyncQueue?.status?.toUpperCase())) {
                this.asqDetailsShow = true;
                this.auftragCreateIsBusy = false;
                clearInterval(this.setIntervalVar);
            }
        },
        pollingStart(intervalSeconds = 10) {
            // IMPORTANT: needed due to mounted call (in case the user refreshes/enters the page during the import is running)
            this.isBusyDataImport = true;
            this.pollData();
            this.setIntervalVar = setInterval(() => {
                this.pollData();
            }, intervalSeconds * 1000);
        },
    },
    mounted() {
        if (this.compDataImportIsRunning) {
            // note: in case the user refreshes/enters the page during the import is running
            this.pollingStart();
        }
    },
};
</script>
