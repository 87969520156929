<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !order">
        <template v-if="actAuftragDetail && order">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <b-alert v-if="!actAuftragDetail.isExecutable" class="my-1 p-2" show>
                Schritt nicht ausführbar (
                <i>isExecutable={{ actAuftragDetail.isExecutable }}</i>
                ).
            </b-alert>
            <b-spinner v-if="!device" />
            <div v-else class="my-2">
                <div>
                    <strong>{{ device.factoryNo }}</strong> ({{ device.deviceType }})
                </div>
                <div>{{ device.deviceSuffix }}</div>
            </div>
            <div class="d-flex">
                <b-button @click="onClickRemoveNotPossible" :disabled="compErledigtIsDisabled">
                    Ausbau nicht möglich
                </b-button>
                <b-button class="ml-auto" @click="onClickRemoved" :disabled="compErledigtIsDisabled">
                    Ausgebaut
                </b-button>
            </div>
            <b-button v-if="false" class="my-3 float-right" @click="onClickErledigt" :disabled="compErledigtIsDisabled">
                Erledigt
            </b-button>
        </template>
    </Box>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import devicesAPI from "@/services/api/devices.api";

export default {
    name: "Step_8_1_1",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            device: null,
            navigateToStepNext: null,
            navigateToStepPrevious: null,
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        compErledigtIsDisabled() {
            // ToDo: add all required conditions to disable button erledigt
            return this.actAuftragDetail.isExecutable === false;
        },
    },
    async mounted() {
        devicesAPI.getSingle(this.$route.params.deviceId, { includeDeviceInfos: true }).then((resp) => {
            this.device = resp.data;
        });
        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];
        await auftragDetailsAPI
            .getByOrderIdAndStep(
                this.$route.params.orderId,
                step,
                this.$route.params.devicePoolId,
                this.$route.params.deviceId
            )
            .then((res) => {
                this.setActAuftragDetail(res);
            });
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        onClickNext(step) {
            // ToDo: do whatever is necessary before moving to next step
            // on SUCCESS, navigate to next step
            this.navigateToStepNext = step;
        },
        onClickPrevious(step) {
            // ToDo: do whatever is necessary before moving to previous step
            // on SUCCESS, navigate to previous step
            this.navigateToStepPrevious = step;
        },
        onClickRemoved() {
            devicesAPI.uninstall(this.device.deviceID).then(() => {
                const payloadAuftragDetails = [
                    {
                        op: "replace",
                        path: "/status",
                        value: 202,
                    },
                ];
                auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetails).then(() => {
                    // on SUCCESS, navigate to next step
                    this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
                });
            });
        },
        onClickRemoveNotPossible() {
            const payload = [
                {
                    op: "replace",
                    path: "/status",
                    value: 500,
                },
            ];
            auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payload).then(() => {
                // on SUCCESS, navigate to next step
                this.navigateToStepPrevious = this.actAuftragDetail.stepPrevious;
            });
        },
    },
};
</script>
