import axios from "axios";

import { BToast } from "bootstrap-vue";

import constants from "@/constants/constants";
import store from "@/store/index";
import router from "@/router";

const HEADER_NAME_ORDER_ID = "X-REtasks-OrderId";
const HEADER_NAME_STEP = "X-REtasks-Step";
const DEFAULT_ORDER_ID = "N/A";
const DEFAULT_STEP = "N/A";

const config = {
    baseURL: constants.env.API_SMARTMETER_URL,
    headers: {
        "Content-Type": "application/json",
    },
};

// create axios instance
const httpClient = axios.create(config);

const authInterceptor = (config) => {
    config.headers["Authorization"] = `Bearer ${store ? store.getters.oidcAccessToken : "STORE NOT READY"}`;
    return config;
};

const addCustomHeadersInterceptor = (config) => {
    const currentRoute = router?.app?.$route;

    let orderId = DEFAULT_ORDER_ID;
    let step = DEFAULT_STEP;

    if (currentRoute) {
        const { params, path, query, name } = currentRoute;

        if (params && params.orderId) {
            orderId = params.orderId;
        } else if (path) {
            if (path.includes("/orders/")) {
                const orderMatch = path.match(/\/orders\/(\d+)/);
                if (orderMatch && orderMatch[1]) {
                    orderId = orderMatch[1];
                } else if (query && query.auftragId) {
                    orderId = query.auftragId;
                }
            } else if (path.includes("/auftrags/")) {
                const auftragMatch = path.match(/\/auftrags\/(\d+)/);
                if (auftragMatch && auftragMatch[1]) {
                    orderId = auftragMatch[1];
                }
            }
        }

        if (name && name.includes("step")) {
            step = name;
        }
    }

    config.headers[HEADER_NAME_ORDER_ID] = orderId;
    config.headers[HEADER_NAME_STEP] = step;

    return config;
};

// error interceptor
const errorInterceptor = (err) => {
    const apiUrlsToExclude = ["smartpower"];
    const apiHttpCodesToExclude = [404];
    let _showErrorToast = true;
    // Do not show error toast if (apiUrlsToExclude AND apiHttpCodesToExclude)
    if (
        apiHttpCodesToExclude.includes(err?.response?.status) &&
        apiUrlsToExclude.some((url) => err?.response?.config?.url.includes(url))
    ) {
        // ToDo(clarify): indicate in comment why this has to be excluded
        console.warn("API returned an error. No toast is displayed.");
    } else if (err?.code === "ERR_CANCELED") {
        // ToDo: restrict this to specific requests only
        // #18243: suppress toasts when a user cancels an upload manually
        console.warn("Request was canceled. No toast is displayed.");
    } else {
        let message;
        let optTitle = "Fehler";
        let optNoAutoHide = true;
        let bootstrapToast = new BToast();
        if (err.response?.data?.Message) {
            // In case of RelloutException the error message is in err.response?.data?.Message
            message = err.response.data.Message;
        } else if (err.response?.data?.title) {
            // In case of BadRequest the error message is in err.response?.data
            message = err.response?.data?.title;
        } else if (err?.name === "AxiosError") {
            // in case of an axios error the error message is in err.message
            optTitle += " (AxiosError)";
            let err_message = err.message;
            switch (err.code) {
                case "ERR_NETWORK":
                    // note(udo): this is the error the client gets when the network is down (no internet connection)
                    err_message = "Netzwerkfehler";
                    // fix(#20393): do not show toast for network errors -> the wifi icon shows that it is down
                    //   note: due to the 5s ping intervals, the screen would be flooded with toasts after some time
                    _showErrorToast = false;
                    break;
                default:
                    // do nothing (keep original message)
                    break;
            }
            message = `${err_message} - ${err.code}`;
            // autohide for axios errors (e.g. network errors)
            // note: due to polling feature introduced in #19454 (which would flood the screen with toasts in case there is no network connection)
            optNoAutoHide = false;
        } else {
            optTitle += " (ErrorInterceptor)";
            message = "Ein unbekannter Fehler ist aufgetreten -> Bitte REtasks Support kontaktieren";
        }

        if (_showErrorToast) {
            bootstrapToast.$bvToast.toast(message, {
                title: optTitle,
                variant: "danger",
                toaster: "b-toaster-bottom-right",
                noAutoHide: optNoAutoHide,
                appendToast: true,
            });
        }
    }
    return Promise.reject(err);
};

// response interceptor
const responseInterceptor = (response) => {
    switch (response.status) {
        case 201:
            break;
        default:
    }
    return response;
};

// use interceptors
httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(addCustomHeadersInterceptor);
httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export { httpClient };
