<template>
    <div>
        <Box class="mb-5" :headerText="`Aufträge (${items.length})`" noBody>
            <b-table
                :busy="isLoading"
                class="mb-0"
                :fields="fields"
                hover
                :items="items"
                show-empty
                thead-class="d-none"
            >
                <template #table-busy>
                    <div class="text-center">
                        <b-spinner class="align-middle my-3 mr-1" small />
                        <strong>Loading...</strong>
                    </div>
                </template>
                <template #empty>
                    Keine Aufträge.
                </template>
                <template #cell(custom)="row">
                    <div class="d-flex">
                        <div>
                            <div :class="!!row.item.executionDatePlanned ? '' : 'text-secondary'">
                                <font-awesome-icon
                                    v-if="row.item.installationPlanStatusAmpel"
                                    class="mr-1"
                                    :class="getStatusVariant(row.item.installationPlanStatusAmpel)"
                                    :icon="['fas', 'circle']"
                                />
                                <template v-if="row.item.executionDatePlanned">
                                    {{ row.item.executionDatePlanned | formatDate(`${dateFormat} HH:mm`) }} Uhr
                                    <font-awesome-icon
                                        v-if="row.item.executionDateAgreement !== null"
                                        :icon="['fas', executionDatePlannedFaIcon(row.item)]"
                                        class="mx-1"
                                        :beat-fade="executionDatePlannedFaBeatFade(row.item)"
                                    />
                                </template>
                                <template v-else>
                                    {{ row.item.fromDate | formatDate(dateFormat) }} -
                                    {{ row.item.toDate | formatDate(dateFormat) }}
                                </template>
                            </div>
                            <div v-if="row.item.showEditAddress">
                                <font-awesome-icon class="re-icon" :icon="['fas', 'fa-map-marked-alt']" />
                                {{ row.item.address }}, {{ row.item.city }}
                            </div>
                            <div v-else style="cursor: pointer" @click="onAddressClick(row.item)">
                                <font-awesome-icon class="re-icon" :icon="['fas', 'fa-map-marked-alt']" />
                                {{ row.item.address }}, {{ row.item.city }}
                            </div>
                            <div class="d-flex small text-secondary">
                                <div class="re-icon-width">
                                    <font-awesome-icon :icon="['fas', 'stopwatch']" />
                                </div>
                                {{ formatRolloutSeconds(row.item.rolloutSeconds) }} Std. |
                                <span
                                    class="ml-1"
                                    :class="`${row.item.auftragStatusID === 'in progress' ? 're-blink' : ''}`"
                                >
                                    <font-awesome-icon :icon="['fas', auftragStatusFaIcon(row.item.auftragStatusID)]" />
                                    {{ row.item.auftragStatusID.toUpperCase() | translateAuftragStatus }}
                                </span>
                                <span v-if="false" class="ml-1">
                                    <!-- ToDo: enable when business wants to start using this functionality (and #20331 has been implemented) -->
                                    |
                                    <font-awesome-icon
                                        v-if="row.item?.realityCheckCompleted === true"
                                        :icon="['fas', 'file-circle-check']"
                                        size="lg"
                                        title="SUCCESS: Prüfung 'Auftrag vs. Realität' erfolgreich durchgeführt"
                                    />
                                    <font-awesome-icon
                                        v-else-if="row.item?.realityCheckCompleted === false"
                                        :icon="['fas', 'file-circle-exclamation']"
                                        size="lg"
                                        title="FAILURE: Prüfung 'Auftrag vs. Realität' nicht(!) erfolgreich durchgeführt"
                                    />
                                    <font-awesome-icon
                                        v-else
                                        :icon="['fas', 'file-circle-question']"
                                        size="lg"
                                        title="'Prüfung Auftrag vs. Realität' (noch) nicht durchgeführt"
                                    />
                                </span>
                            </div>
                            <div class="d-flex small text-danger" v-if="row.item.rollouted === -1">
                                <div class="re-icon-width re-icon ">
                                    <font-awesome-icon :icon="['fas', 'circle-minus']" />
                                </div>
                                <span>Blockiert</span>
                            </div>
                            <div class="d-flex small text-danger" v-if="row.item.rollouted === -1">
                                <span class="re-row-margin">{{
                                    convertRolloutBlockierungEnumToString(row.item.rolloutBlockierung)
                                }}</span>
                            </div>
                            <div class="d-flex small text-secondary">
                                <div class="re-icon-width">
                                    <font-awesome-icon :icon="['fas', 'tachograph-digital']" />
                                </div>
                                <!-- DZ - Direktzähler (ohne Wandler)" -->
                                {{ row.item.directZaehler }} DZ
                                <!-- breaker -->
                                <template v-if="row.item.breakerZaehlerDavon">
                                    <b-badge class="mx-1" variant="warning">
                                        {{ row.item.breakerZaehlerDavon }} Breaker
                                    </b-badge>
                                </template>
                                <!-- WZ - Wandlerzähler (mit Wandler) -->
                                | {{ row.item.wandlerZaehler }} WZ
                                <!-- GS - Gerätestandort -->
                                | {{ row.item.geraetestandorte }} GS
                                <span>
                                    <b-badge class="ml-1" :variant="commTech(row.item) === 'LTE' ? 'warning' : 'light'">
                                        {{ commTech(row.item) }}
                                    </b-badge>
                                </span>
                            </div>
                            <template v-if="activeTabIndex !== 0">
                                <div class="d-flex small text-secondary">
                                    <div class="re-icon-width">
                                        <font-awesome-icon :icon="['fas', 'user-tie']" />
                                    </div>
                                    <template v-if="row.item?.manager?.userID">
                                        {{ row.item.manager.fullName }} - {{ row.item.manager?.company?.name }}
                                    </template>
                                    <template v-else>
                                        Kein Leiter zugewiesen
                                    </template>
                                </div>
                                <div class="d-flex small text-secondary">
                                    <div class="re-icon-width">
                                        <font-awesome-icon :icon="['fas', 'user']" class="mr-1" />
                                    </div>
                                    <template v-if="row.item?.technician?.userID === null">
                                        Kein Techniker zugewiesen
                                    </template>
                                    <template v-else-if="currentUser?.userID === row.item?.technician?.userID">
                                        Mir zugewiesen
                                    </template>
                                    <template v-else>
                                        {{ row.item?.technician?.fullName }}
                                    </template>
                                </div>
                            </template>
                            <div class="d-flex small text-secondary">
                                <div class="re-icon-width">
                                    <font-awesome-icon :icon="['fas', 'house-chimney-crack']" />
                                </div>
                                {{ row.item.tp }}
                                <span class="ml-1">
                                    <font-awesome-icon :icon="['fas', 'hashtag']" />{{ row.item.auftragID }}
                                </span>
                            </div>
                        </div>
                        <div class="ml-auto">
                            <b-button
                                :disabled="!userIsAllowedToClickButton(row.item.auftragStatusID)"
                                class="re-button-width"
                                variant="primary"
                                @click="onClickOrder(row.item)"
                            >
                                <font-awesome-icon
                                    v-if="row.item.auftragStatusID === 'creating'"
                                    :icon="['fas', 'hourglass-half']"
                                    size="lg"
                                    spin
                                />
                                <font-awesome-icon
                                    v-else-if="row.item.auftragStatusID === 'error'"
                                    :icon="['fas', 'triangle-exclamation']"
                                    size="lg"
                                    beat-fade
                                />
                                <font-awesome-icon
                                    v-else-if="['new', 'created', 'in progress'].includes(row.item.auftragStatusID)"
                                    :icon="['fas', 'play-circle']"
                                    size="lg"
                                />
                                <font-awesome-icon
                                    v-else
                                    :icon="['fas', 'circle-question']"
                                    size="lg"
                                    beat-fade
                                    title="Auftragsstatus unbekannt"
                                />
                            </b-button>
                            <b-button @click="onEditClick(row.item)" class="d-block ml-auto mt-1" size="sm">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" />
                            </b-button>
                        </div>
                    </div>
                    <div v-if="!row.item.showEditAddress && row.item.showMap" :key="row.item.auftragID + '_map'">
                        <l-map
                            :center="[row.item?.latitude, row.item?.longitude]"
                            :maxZoom="18"
                            :minZoom="10"
                            style="height: 300px; width: 100%; z-index: 0"
                            :zoom="18"
                        >
                            <l-tile-layer :url="url" :attribution="attribution" />
                            <l-circle-marker
                                color="red"
                                :lat-lng="[row.item?.latitude, row.item?.longitude]"
                                :radius="10"
                            />
                        </l-map>
                    </div>
                    <!-- ToDo(clarify): a modal would be the simpler solution -->
                    <b-card
                        v-if="row.item.showEditAddress"
                        body-class="p-2"
                        :key="row.item.auftragID + '_edit-address'"
                    >
                        <div>Ausführungszeitraum gemäss Planung:</div>
                        <div>
                            {{ row.item.fromDate | formatDate(dateFormat) }} -
                            {{ row.item.toDate | formatDate(dateFormat) }}
                        </div>
                        <b-card body-class="p-2">
                            <b-card-text>
                                <div>Neuer Ausführungstermin:</div>
                                <b-form-datepicker
                                    v-model="row.item.executionDatePlannedNew.date"
                                    class="mb-2"
                                    locale="de"
                                />
                                <b-form-timepicker
                                    v-model="row.item.executionDatePlannedNew.time"
                                    locale="de"
                                    label-close-button="Schliessen"
                                />
                                <b-form-radio-group class="mt-3" stacked v-model="row.item.executionDateAgreementNew">
                                    <b-form-radio value="0" @change="onClickExecutionDateAgreement(row.item, 0)">
                                        <div>Termin mit Kunde vereinbart</div>
                                        <div class="small text-secondary">
                                            (Die Uhrzeit wird mit einem * markiert.)
                                        </div>
                                    </b-form-radio>
                                    <b-form-radio value="1" @change="onClickExecutionDateAgreement(row.item, 1)">
                                        Termin selbst festgelegt
                                    </b-form-radio>
                                </b-form-radio-group>
                                <div class="text-center mt-2">
                                    <b-button size="sm" @click="onClickSaveExecutionDateNew(row.item)">
                                        Speichern
                                    </b-button>
                                    <b-button class="mx-3" size="sm" @click="onClickCancelExecutionDateNew(row.item)">
                                        Abbrechen
                                    </b-button>
                                    <b-button variant="primary" size="sm" @click="onClickRemoveExecutionDate(row.item)">
                                        <font-awesome-icon :icon="['fas', 'trash']" size="lg" />
                                    </b-button>
                                </div>
                            </b-card-text>
                        </b-card>
                    </b-card>
                </template>
            </b-table>
        </Box>
        <DxTabs
            class="container"
            :selected-index="activeTabIndex"
            :data-source="dataSource"
            :disabled="isLoading || isPopupOpen"
            @item-click="onItemClick"
            @item-rendered="onItemRendered"
        />
    </div>
</template>

<script>
import moment from "moment";
import "moment/locale/de";
import _ from "lodash";

import DxTabs from "devextreme-vue/tabs";

import constants from "@/constants/constants";
import auftragsAPI from "@/services/api/auftrags.api";
import auftragAusfuehrungApi from "@/services/api/rolloutview/auftragAusfuehrung.api";
import "leaflet/dist/leaflet.css";
import { mapGetters } from "vuex";
import userCompanyPermissionService from "@/services/userCompanyPermissionService";

import { LCircleMarker, LMap, LTileLayer } from "vue2-leaflet";

const DATE_FORMAT_INPUT = "YYYY-MM-DDTHH:mm:ss";

export default {
    name: "ExecutionList",
    components: {
        LCircleMarker,
        LMap,
        LTileLayer,
        DxTabs,
    },
    data() {
        return {
            attribution:
                '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> | &copy; <a href="http://www.openstreetmap.org/about/">OpenStreetMap</a> | <a href="https://www.mapbox.com/map-feedback/#/-74.5/40/10">Improve map</a>',
            dateFormat: "dd., DD.MM.YYYY",
            fields: [{ key: "custom" }],
            items: [],
            isLoading: true,
            url:
                "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWktcmVwb3dlciIsImEiOiJja2t6anF6dnUzMXB4MnFxbm16ejVyMXJlIn0.Wf_akGZUp0tghsSMYJRf4g",
            activeTabIndex: 0,
            isPopupOpen: false,
            dataSource: [
                {
                    id: constants.execList.tabs.PERSONAL_ITEM_ID,
                    text: "Eigene",
                    icon: "user",
                },
                {
                    id: constants.execList.tabs.COMPANY_ITEM_ID,
                    text: "Firma nicht verfügbar",
                    icon: "group",
                    visible: false,
                },
                {
                    id: constants.execList.tabs.ADMIN_ITEM_ID,
                    text: "Alle",
                    icon: "group",
                    visible: false,
                },
            ],
        };
    },
    computed: {
        ...mapGetters(["currentUser"]),
        isUserAdmin() {
            return this.currentUser ? userCompanyPermissionService.isUserAdmin(this.currentUser.userRoles) : false;
        },
    },
    async mounted() {
        moment.locale("de");
        await this.loadOrdersByActiveTabIndex();
        if (this.isUserAdmin) {
            this.enableAdminView();
        }
        this.adjustCompanyTab();
    },
    methods: {
        auftragStatusFaIcon(status) {
            switch (status.toUpperCase()) {
                case "CREATED":
                    return "rectangle-list";
                case "CREATING":
                    return "hourglass-half";
                case "ERROR":
                    return "triangle-exclamation";
                case "NEW":
                    return "calendar-days";
                case "IN PROGRESS":
                    return "play";
                default:
                    return "circle-question";
            }
        },
        commTech(data) {
            if (data.selectedCommunicationTechnology) {
                return data.selectedCommunicationTechnology;
            } else if (data.eaCommunicationTechnology) {
                return data.eaCommunicationTechnology;
            } else {
                return "-";
            }
        },
        convertRolloutBlockierungEnumToString(item) {
            return constants.RolloutBlockierungDescriptions.find((rb) => rb.id === item)?.name;
        },
        enableAdminView() {
            var adminViewIndex = this.getIndexById(constants.execList.tabs.ADMIN_ITEM_ID);
            this.enableTabByIndex(adminViewIndex);
        },
        executionDatePlannedFaBeatFade(a) {
            const at = this.findAgreementTypeByValue(a.executionDateAgreement);
            switch (at?.value) {
                case undefined:
                case 0:
                    return true;
                default:
                    return false;
            }
        },
        executionDatePlannedFaIcon(a) {
            const at = this.findAgreementTypeByValue(a.executionDateAgreement);
            switch (at?.value) {
                case 0:
                    return "handshake";
                case 1:
                    return "helmet-safety";
                case 2:
                    return "robot";
                default:
                    // unhandled values
                    return "triangle-exclamation";
            }
        },
        findAgreementTypeByValue(value) {
            return constants.selectableExecutionDateAgreementTypes.find((at) => at.value === value);
        },
        formatRolloutSeconds(s) {
            switch (s) {
                case null:
                    return "Nicht verfügbar";
                default:
                    return new Date(s * 1000).toISOString().slice(11, 16);
            }
        },
        adjustCompanyTab() {
            var companyViewIndex = this.getIndexById(constants.execList.tabs.COMPANY_ITEM_ID);
            this.dataSource[companyViewIndex].text = this.currentUser?.company.name ?? "Nicht verfügbar";
            this.enableTabByIndex(companyViewIndex);
        },
        getIndexById(id) {
            return this.dataSource.findIndex((obj) => obj.id === id);
        },
        enableTabByIndex(index) {
            this.dataSource[index].visible = true;
        },
        onItemRendered(e) {
            // sets tab id (used for tab switching)
            e.itemElement.setAttribute("data-tab-id", e.itemData.id);
        },
        // ToDo: use onSelectionChanged instead of onItemClick
        //   -> https://js.devexpress.com/Vue/Documentation/ApiReference/UI_Components/dxTabs/Configuration/#onSelectionChanged
        //   note: that might also simplify collateral logic
        async onItemClick(e) {
            const itemIndex = e.itemData.id;
            const anyItemShowEditAddress = this.items.some((item) => item.showEditAddress);
            if (anyItemShowEditAddress) {
                const result = await this.openReloadModal(itemIndex === this.activeTabIndex);
                if (result) {
                    this.updateActiveTab(itemIndex);
                    await this.loadOrdersByActiveTabIndex();
                }
                this.changeTabClasses();
                this.isPopupOpen = false;
            } else {
                this.updateActiveTab(itemIndex);
                await this.loadOrdersByActiveTabIndex();
            }
        },
        updateActiveTab(index) {
            this.activeTabIndex = index;
        },
        changeTabClasses() {
            this.$nextTick(() => {
                const tabs = this.$el.querySelectorAll(".dx-tab");

                tabs.forEach((tab) => {
                    const tabId = tab.getAttribute("data-tab-id");
                    if (tab.classList.contains("dx-tab-selected")) {
                        tab.classList.remove("dx-tab-selected");
                        tab.setAttribute("aria-selected", "false");
                    }
                    if (tabId === `${this.activeTabIndex}`) {
                        tab.classList.add("dx-tab-selected");
                        tab.setAttribute("aria-selected", "true");
                    }
                });
            });
        },
        async openReloadModal(sameTab) {
            this.isPopupOpen = true;
            const tabInput = sameTab ? "neu laden" : "wechseln";
            return this.$bvModal.msgBoxConfirm(
                `Es sind noch Aufträge in Bearbeitung. Wenn Sie den Tab ${tabInput}, verwerfen Sie alle gespeicherten Änderungen.`,
                {
                    title: `Tab ${tabInput}?`,
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: `${_.startCase(_.toLower(tabInput))}`,
                    cancelTitle: "Abbrechen",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                }
            );
        },
        async loadOrdersByActiveTabIndex() {
            let companyFilter = false;
            let personalFilter = false;
            switch (this.activeTabIndex) {
                case 0: // 0 --> personal
                    personalFilter = true;
                    break;
                case 1: // 1 --> company
                    companyFilter = true;
                    break;
            }
            await this.ordersLoad(companyFilter, personalFilter);
        },
        userIsAllowedToClickButton(auftragStatusID) {
            let userIsAllowedToClickButton = false;
            switch (auftragStatusID) {
                case "in progress":
                    // everyone can click (current behaviour is like this -> should be more restrictive)
                    userIsAllowedToClickButton = true;
                    break;
                default:
                    // handle all other cases
                    // note: this should be "false" but actually it is fulfilling the current behaviour of the app
                    userIsAllowedToClickButton = true;
            }
            return userIsAllowedToClickButton;
        },
        onAddressClick(item) {
            item.showMap = !item.showMap;
        },
        onEditClick(item) {
            // ToDo: refactor this ugly solution of handling this... -> use form validation
            const isSameDate =
                item.executionDatePlannedNew.date ===
                this.compDate(item.executionDatePlanned, DATE_FORMAT_INPUT, "YYYY-MM-DD");
            const isSameTime =
                item.executionDatePlannedNew.time ===
                this.compDate(item.executionDatePlanned, DATE_FORMAT_INPUT, "HH:mm:ss");
            const isSameAgreement = item.executionDateAgreement === item.executionDateAgreementNew;
            if (item.showEditAddress && (!isSameDate || !isSameTime || !isSameAgreement)) {
                this.$bvModal
                    .msgBoxConfirm(
                        "Sie haben die Änderungen noch nicht gespeichert. Wollen Sie die Änderungen verwerfen?",
                        {
                            title: "Änderungen verwerfen?",
                            size: "sm",
                            buttonSize: "sm",
                            okVariant: "danger",
                            okTitle: "Verwerfen",
                            cancelTitle: "Abbrechen",
                            footerClass: "p-2",
                            hideHeaderClose: false,
                            centered: true,
                        }
                    )
                    .then((value) => {
                        if (value) {
                            item.showEditAddress = false;
                            item.executionDatePlannedNew.date = this.compDate(
                                item.executionDatePlanned,
                                DATE_FORMAT_INPUT,
                                "YYYY-MM-DD"
                            );
                            item.executionDatePlannedNew.time = this.compDate(
                                item.executionDatePlanned,
                                DATE_FORMAT_INPUT,
                                "HH:mm:ss"
                            );
                            item.executionDateAgreementNew = item.executionDateAgreement;
                        }
                    });
            } else {
                item.showEditAddress = !item.showEditAddress;
            }
        },
        compDate(dateString, formatInput = DATE_FORMAT_INPUT, formatOutput = "DD.MM.YYYY, HH:mm") {
            const mom = moment(dateString, formatInput);
            return mom.isValid() ? mom.format(formatOutput) : null;
        },
        onClickSaveExecutionDateNew(item) {
            this.$bvModal
                .msgBoxConfirm("Möchten Sie den Ausführungstermin speichern?", {
                    title: "Ausführungstermin speichern",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "Speichern",
                    cancelTitle: "Abbrechen",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        this.handleSaveOrder(item);
                    }
                });
        },
        handleSaveOrder(item) {
            if (
                !!item.executionDatePlannedNew.date &&
                !!item.executionDatePlannedNew.time &&
                item.executionDateAgreementNew !== null
            ) {
                const payload = [
                    {
                        op: "replace",
                        path: "/executionDatePlanned",
                        value: `${item.executionDatePlannedNew.date}T${item.executionDatePlannedNew.time}`,
                    },
                    {
                        op: "replace",
                        path: "/executionDateAgreement",
                        value: item.executionDateAgreementNew,
                    },
                ];
                auftragsAPI.patch(item.auftragID, payload).then((resp) => {
                    item.executionDatePlanned = resp.executionDatePlanned;
                    item.executionDatePlannedNew.date = this.compDate(
                        resp.executionDatePlanned,
                        DATE_FORMAT_INPUT,
                        "YYYY-MM-DD"
                    );
                    item.executionDatePlannedNew.time = this.compDate(
                        resp.executionDatePlanned,
                        DATE_FORMAT_INPUT,
                        "HH:mm:ss"
                    );
                    item.executionDateAgreement = resp.executionDateAgreement;
                });
                item.showEditAddress = false;
            } else {
                this.$bvToast.toast("Bitte erfassen Sie das Datum, die Uhrzeit sowie die Vereinbarungsart.", {
                    title: "Fehler",
                    variant: "danger",
                    toaster: "b-toaster-bottom-right",
                    noAutoHide: true,
                    appendToast: true,
                });
            }
        },
        onClickCancelExecutionDateNew(item) {
            this.$bvModal
                .msgBoxConfirm("Möchten Sie den Ausführungstermin wirklich verwerfen?", {
                    title: "Ausführungstermin verwerfen",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "Verwerfen",
                    cancelTitle: "Abbrechen",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        item.executionDatePlannedNew.date = this.compDate(
                            item.executionDatePlanned,
                            DATE_FORMAT_INPUT,
                            "YYYY-MM-DD"
                        );
                        item.executionDatePlannedNew.time = this.compDate(
                            item.executionDatePlanned,
                            DATE_FORMAT_INPUT,
                            "HH:mm:ss"
                        );
                        item.executionDateAgreementNew = item.executionDateAgreement;
                        item.showEditAddress = false;
                    }
                });
        },
        onClickRemoveExecutionDate(item) {
            this.$bvModal
                .msgBoxConfirm("Möchten Sie den Ausführungstermin wirklich löschen?", {
                    title: "Ausführungstermin löschen",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "Löschen",
                    cancelTitle: "Abbrechen",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        this.handleRemoveOrder(item);
                    }
                });
        },
        handleRemoveOrder(item) {
            const payload = [
                {
                    op: "remove",
                    path: "/executionDatePlanned",
                },
                {
                    op: "remove",
                    path: "/executionDateAgreement",
                },
            ];
            auftragsAPI.patch(item.auftragID, payload).then((resp) => {
                item.executionDatePlanned = resp.executionDatePlanned;
                item.executionDatePlannedNew.date = null;
                item.executionDatePlannedNew.time = null;
                item.executionDateAgreement = null;
                item.executionDateAgreementNew = null;
            });
            item.showEditAddress = false;
        },
        onClickExecutionDateAgreement(item, value) {
            item.executionDateAgreementNew = value;
        },
        getStatusVariant(status) {
            switch (status) {
                case constants.installationPlanStatusAmpel.RED:
                    return "text-danger";
                case constants.installationPlanStatusAmpel.YELLOW:
                    return "text-warning";
                case constants.installationPlanStatusAmpel.GREEN:
                    return "text-success";
            }
        },
        onClickOrder(item) {
            const hasShowEditAddress = this.items.some((item) => item.showEditAddress);
            //rollouted === -1 -> blockiert
            if (item.rollouted === -1) {
                const rolloutBlockierungDescription =
                    constants.RolloutBlockierungDescriptions.find((rb) => rb.id === item.rolloutBlockierung)?.name ??
                    "";
                const modalMessage = `Dieser Auftrag ist blockiert. Grund: ${rolloutBlockierungDescription}. Möchten Sie den Auftrag wirklich ausführen?`;
                this.showConfirmationModal(modalMessage, "Auftrag blockiert. Ausführen?").then((confirmed) => {
                    if (confirmed) {
                        this.handleItemAction(item);
                    }
                });
            } else if (hasShowEditAddress) {
                const modalMessage =
                    "Es sind noch Aufträge in Bearbeitung. Wenn sie fortfahren verwerfen Sie alle Änderungen.";
                this.showConfirmationModal(modalMessage, "Auftrag öffnen?").then((confirmed) => {
                    if (confirmed) {
                        this.handleItemAction(item);
                    }
                });
            } else {
                this.handleItemAction(item);
            }
        },
        handleItemAction(item) {
            this.onClickOrderOpen(item.auftragID);
        },
        onClickOrderOpen(orderId) {
            this.$router.push({ name: "execution-single", params: { orderId: orderId.toString() } });
        },
        ordersLoad(excludeAllExceptOwnCompany = false, excludeAllExceptPersonals = false) {
            this.isLoading = true;
            return auftragAusfuehrungApi
                .get({
                    // get all statuses which are handled in the view
                    status__in: ["new", "creating", "error", "created", "in progress"].join(","),
                    // ToDo(backend/frontend): only get aufträge where Auftrag.ManagerID is not NULL #19454
                    // proposal:
                    //   managerID__isnull: false -> backend: consider param only if it is passed (AND condition)
                    excludeAllExceptOwnCompany: excludeAllExceptOwnCompany,
                    excludeAllExceptPersonals: excludeAllExceptPersonals,
                })
                .then((r) => {
                    const _rData = r.data;
                    _rData.map((i) => {
                        i.showMap = false;
                        i.showEditAddress = false;
                        i.executionDateAgreementNew = i.executionDateAgreement;
                        i.executionDatePlannedNew = {};
                        i.executionDatePlannedNew.date = this.compDate(
                            i.executionDatePlanned,
                            DATE_FORMAT_INPUT,
                            "YYYY-MM-DD"
                        );
                        i.executionDatePlannedNew.time = this.compDate(
                            i.executionDatePlanned,
                            DATE_FORMAT_INPUT,
                            "HH:mm:ss"
                        );
                    });
                    const _respGrouped = Object.groupBy(_rData, ({ auftragStatusID }) => auftragStatusID);
                    const _respGroupedSorted = [];
                    const _respGroupedKeys = Object.keys(_respGrouped);
                    const _statusSortOrder = ["in progress", "error", "created", "new", "creating"];
                    _statusSortOrder.forEach((_status) => {
                        if (_respGroupedKeys.includes(_status)) {
                            _respGroupedSorted.push(..._.sortBy(_respGrouped[_status], ["fromDate"]));
                        }
                    });
                    this.items = _respGroupedSorted;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        showConfirmationModal(message, title) {
            return this.$bvModal.msgBoxConfirm(message, {
                title: title,
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Fortfahren",
                cancelTitle: "Abbrechen",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true,
            });
        },
    },
};
</script>

<style scoped>
/* css blinking #20382 */
.re-blink {
    animation: re-blinker 1s infinite;
}
@keyframes re-blinker {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    to {
        opacity: 1;
    }
}
.re-button-width {
    min-width: 55px;
}
.re-icon-width {
    width: 23px;
}
.re-light-grey {
    color: #d3d3d3;
}
.re-row-margin {
    margin-left: 23px;
}
.container {
    z-index: 99999;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 0;
}
::v-deep .dx-item[aria-selected="true"] {
    background-color: rgb(255, 176, 172);
}
::v-deep .dx-item {
    border: 1px solid rgb(197, 197, 197);
    border-radius: 3px;
}
</style>
