<template>
    <Main>
        <Box :isLoading="!order" headerText="Gerätestandort" :headerInfo="'#' + order?.auftragID">
            <div v-if="order" class="d-flex font-weight-bold">
                <div>
                    <router-link :to="{ name: 'execution-single', params: { orderId: order.auftragID.toString() } }">
                        {{ order.hak.tp }}
                    </router-link>
                </div>
                <div class="ml-auto">
                    {{ order.hak.address }}
                    <div v-if="false">{{ order.hak.postalCode }} {{ order.hak.city }}</div>
                </div>
            </div>
        </Box>
        <Box headerText="Callcenter Aktivität" :isLoading="!devicepools">
            <template v-if="devicepools">
                <template v-if="devicepools.length === 0">
                    <b-alert class="p-2" show>
                        Keine Gerätestandorte gefunden.
                    </b-alert>
                </template>
                <template v-else>
                    <b-form-group class="mt-2">
                        <div v-for="(dp, i) in devicepools" :key="`dp_${i}`">
                            <b-form-radio v-model="selected" :value="i">
                                <div>
                                    {{ dp.position }}
                                    <span v-if="dp.positionDetail || dp.positionDetail.length">
                                        - {{ dp.positionDetail }}
                                    </span>
                                </div>
                                <div>{{ dp.address }}</div>
                                <div>{{ dp.postalCode }} {{ dp.city }}</div>
                            </b-form-radio>
                        </div>
                    </b-form-group>
                    <b-form-group label="Bitte erfassen Sie eine konkrete Anweisung:">
                        <b-form-textarea v-model="textarea" rows="6" placeholder="Indiv. Text" class="font-italic" />
                    </b-form-group>
                </template>
                <b-button @click="onClickSend" :disabled="!this.textarea || !this.devicepools" block>Senden</b-button>
            </template>
        </Box>
    </Main>
</template>

<script>
import constants from "@/constants/constants";
import auftragsAPI from "@/services/api/auftrags.api";
import devicePoolsAPI from "@/services/api/devicepools.api";
import iseAPI from "@/services/api/ise.api";

export default {
    name: "ExecutionCallcenter",
    data() {
        return {
            devicepools: null,
            order: null,
            orderId: this.$route.params.orderId.toString(),
            selected: 0,
            textarea: "",
        };
    },
    mounted() {
        const hakId = this.$route.params.hakId;
        if (hakId) {
            devicePoolsAPI.get({ hakID: hakId }).then((resp) => {
                this.devicepools = resp;
            });
        } else {
            this.$router.push({ name: "execution-single", params: { orderId: this.orderId } });
        }
        auftragsAPI.getSingle(this.$route.params.orderId).then((resp) => {
            this.order = resp.data;
        });
    },
    methods: {
        async onClickSend() {
            if (this.devicepools && this.devicepools[this.selected]) {
                const iseActivityID = await this.saveActivityOnIse(this.devicepools[this.selected]);
                console.log("ISE Activity ID", iseActivityID);
            }
            // on SUCCESS return to "execution-single"
            await this.$router.push({ name: "execution-single", params: { orderId: this.orderId } });
        },
        // Save actitiy to ISE and return activityID
        async saveActivityOnIse(devicePool) {
            const payload = {
                activityTypeID: constants.externalServices.ISE_ACTIVITY_CALLCENTER, // constant for  Aktivitaet "Gefahrenkontrolle"
                referenceNumber: devicePool.iseDevicePoolID, // iseID of the devicePool
                startDate: this.order.fromDate,
                endDate: this.order.toDate,
                message: this.textarea,
            };
            const isDev = ["development", "integration"].includes(constants.env.NAME);
            if (!isDev) {
                const response = await iseAPI.postActivity(payload);
                return response.data.activityID;
            } else {
                console.log(`Skipping call to ISE - Env: ${constants.env.NAME}`);
                return 1;
            }
        },
    },
};
</script>
