<template>
    <BoxBody :variant="compStatusVariant">
        <div class="d-flex">
            <div>
                <div>
                    {{ dp.position }}
                    <span v-if="dp.positionDetail && dp.positionDetail.length"> - {{ dp.positionDetail }}</span>
                    <span v-if="dp.positionDescription"
                        >, <br />
                        {{ dp.positionDescription }}</span
                    >
                </div>
                <div>{{ dp.address }}</div>
                <div v-if="false">{{ dp.postalCode }} {{ dp.city }}</div>
            </div>
            <div class="ml-auto" />
            <b-button class="ml-auto h-100" @click="onClickGs(dp.devicePoolID)">GS</b-button>
        </div>
        <div v-if="dp.zugangInfo !== null && dp.zugangInfo.length">
            <font-awesome-icon :icon="['fas', 'info-circle']" size="lg" />
            {{ dp.zugangInfo }}
        </div>
        <div class="d-flex mt-2">
            <div class="d-inline-flex">
                <font-awesome-icon :icon="['fas', 'key']" size="lg" />
                <div class="mx-2" v-if="editKeyPosition">
                    <b-form-select v-model="keyPositionCode" :options="optionsKeyPositionCode" />
                    <b-form-textarea v-model="keyPositionDescription" class="d-inline-flex" />
                </div>
                <div v-else class="ml-1">
                    <div>{{ keyPositionCode }}</div>
                    <div>{{ keyPositionDescription }}</div>
                </div>
            </div>
            <b-button class="ml-auto h-100" @click="editKeyPosition = !editKeyPosition" size="sm">
                <span><font-awesome-icon :icon="['fas', 'pencil-alt']"/></span>
            </b-button>
        </div>
        <b-button
            v-if="editKeyPosition"
            class="d-flex ml-auto"
            size="sm"
            @click="onClickSaveKeyPosition"
            :disabled="isSavingKeyPosition"
        >
            Speichern
        </b-button>
        <HorizontalSeparator />
        <div class="small">
            <b-spinner v-if="progressStatus.total === 0" class="d-block" small />
            <div v-else>
                Status:
                <span v-if="progressStatus.completed.length === 0">Nicht begonnen</span>
                <span v-else-if="progressStatus.completed.length === progressStatus.total">Beendet</span>
                <span v-else>In Bearbeitung</span>
                ({{ progressStatus.completed.length }}/{{ progressStatus.total }})
            </div>
        </div>
    </BoxBody>
</template>

<script>
import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import devicePoolsAPI from "@/services/api/devicepools.api";

export default {
    name: "ExecGsSummaryNew",
    props: {
        dp: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            auftragDetails911: {},
            editKeyPosition: false,
            isLoadingAuftragDetails911: true,
            isSavingKeyPosition: false,
            keyPositionCode: this.dp.keyPositionCode,
            keyPositionDescription: this.dp.keyPositionDescription,
            optionsKeyPositionCode: [
                { value: "Auslesesteckdose", text: "Auslesesteckdose" },
                { value: "Aussenzählerkasten", text: "Aussenzählerkasten" },
                { value: "Schlüsseldepot", text: "Schlüsseldepot" },
                { value: "Schlüsselfach", text: "Schlüsselfach" },
                { value: "Schlüsselkasten", text: "Schlüsselkasten" },
                { value: "Schlüsselrohr", text: "Schlüsselrohr" },
                { value: "Schlüsselverwaltung", text: "Schlüsselverwaltung" },
            ],
            progressStatus: {
                total: 0,
                in_progress: [],
                completed: [],
                blocked: [],
                todo: [],
            },
        };
    },
    computed: {
        comp911statusCompleted() {
            let comp911statusCompleted = false;
            if (this.isLoadingAuftragDetails911) {
                comp911statusCompleted = false;
            } else {
                comp911statusCompleted = this.auftragDetails911.status === 202;
            }
            return this.step911override ? true : comp911statusCompleted;
        },
        compStatusVariant() {
            if (this.comp911statusCompleted) {
                return "success";
            } else if (this.progressStatus.completed.length === 0) {
                return "";
            } else if (this.progressStatus.blocked.length > 0) {
                return "danger";
            } else {
                return "warning";
            }
        },
    },
    async mounted() {
        this.isLoadingAuftragDetails911 = true;
        await auftragDetailsAPI
            .getByOrderIdAndStep(
                this.$route.params.orderId,
                "9-1-1",
                this.$route.params.devicePoolId ?? this.dp.devicePoolID, // gae: fix bug "Unhandled Scenario: Result contains 2 record. Expected: 0 or 1 records" while loading ExecutionSingleNew
                this.$route.params.deviceId
            )
            .then((res) => {
                if (!Array.isArray(res)) {
                    this.auftragDetails911 = res;
                } else {
                    this.$bvToast.toast(
                        "Kein AuftragDetail record für diesen Schritt gefunden -> bitte technischen Support informieren (DEBUG: 'Network' tab -> check payload details of call api/mi-rellout-api/net/auftrag-details)",
                        {
                            title: "Fehler",
                            variant: "danger",
                            toaster: "b-toaster-bottom-right",
                            noAutoHide: true,
                            appendToast: true,
                        }
                    );
                    return Promise.reject();
                }
            })
            .finally(() => {
                this.isLoadingAuftragDetails911 = false;
            });

        this.determineStatus(2, this.dp.devicePoolID).then((resp) => {
            this.progressStatus = resp;
        });
    },
    methods: {
        determineStatus(actionGroupId, devicePoolId) {
            return auftragDetailsAPI
                .get({
                    auftragID: this.$route.params.orderId,
                    actionGroupID: actionGroupId,
                    devicePoolID: devicePoolId,
                })
                .then((resp) => {
                    const stepsStatus = {
                        total: resp.length,
                        in_progress: [],
                        completed: [],
                        blocked: [],
                        todo: [],
                    };
                    resp.forEach((ad) => {
                        switch (true) {
                            case ad.status === 0:
                                stepsStatus.todo.push(ad);
                                break;
                            case ad.status >= 100 && ad.status <= 199:
                                stepsStatus.in_progress.push(ad);
                                stepsStatus.todo.push(ad);
                                break;
                            case ad.status >= 200 && ad.status < 500: // DONE and SKIPPED
                                stepsStatus.completed.push(ad);
                                break;
                            case ad.status >= 500 && ad.status <= 599:
                                stepsStatus.blocked.push(ad);
                                stepsStatus.todo.push(ad);
                                break;
                            default:
                                stepsStatus.todo.push(ad);
                                alert(`auftragDetail.status=${ad.status} not defined!`);
                        }
                    });
                    return stepsStatus;
                });
        },
        onClickGs(dpId) {
            this.$router.push({
                name: "execution-geraetestandort",
                params: { orderId: this.$route.params.orderId, devicePoolId: dpId },
            });
        },
        onClickSaveKeyPosition() {
            const patchDoc = [];
            if (this.keyPositionCode !== this.dp.keyPositionCode) {
                patchDoc.push({
                    op: "replace",
                    path: "/keyPositionCode",
                    value: this.keyPositionCode,
                });
            }
            if (this.keyPositionDescription !== this.dp.keyPositionDescription) {
                patchDoc.push({
                    op: "replace",
                    path: "/keyPositionDescription",
                    value: this.keyPositionDescription,
                });
            }
            if (patchDoc.length) {
                this.isSavingKeyPosition = true;
                devicePoolsAPI.patch(this.dp.devicePoolID, patchDoc).finally(() => {
                    this.isSavingKeyPosition = false;
                    this.editKeyPosition = false;
                });
            } else {
                this.editKeyPosition = false;
            }
        },
    },
};
</script>
