<template>
    <div>
        <div>
            <template>
                <b-alert v-if="alertNoMspClientId" class="my-1 p-2" variant="danger" show>
                    mspClientId nicht definiert.
                </b-alert>
            </template>
        </div>
        <div v-if="isLoadingStatusData">
            <re-spinner></re-spinner>
        </div>
        <div v-else-if="!mspSmartmanager">
            <span class="text-black-50 font-weight-bold">Gerät existiert nicht in MSP</span>
        </div>
        <div v-else>
            <b-row class="my-3" align-v="center">
                <b-col>
                    <template v-if="!alertNoMspClientId">
                        Status: <span class="text-black-50 font-weight-bold">{{ mspSmartmanager.deviceStatus }}</span>
                    </template>
                </b-col>
                <b-col>
                    <b-button
                        class="mt-2 float-right"
                        @click="loadMspSmartmanager"
                        :disabled="compBtnRefreshStatusIsDisabled"
                        variant="primary"
                    >
                        Status aktualisieren
                        <b-spinner v-if="isLoadingStatusData" small />
                    </b-button>
                </b-col>
            </b-row>
            <b-row class="mb-3" align-v="center">
                <b-col>
                    Aktiviert:
                    <span class="text-black-50 font-weight-bold">{{
                        this.compIsDeviceActivatedOnMsp ? "Ja" : "Nein"
                    }}</span>
                </b-col>
                <b-col>
                    <b-button
                        class="mt-2 float-right"
                        @click="onClickMspActivate"
                        :disabled="compBtnActivateIsDisabled"
                        variant="primary"
                    >
                        In MSP aktivieren
                        <b-spinner v-if="isLoadingActivationData" small />
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import ReSpinner from "@/components/energyspace/ReSpinner";

import devicesAPI from "@/services/api/devices.api";
import smartpowerAPI from "@/services/api/smartpower.api";
import mspAPI from "@/services/api/msp.api";

export default {
    name: "ExecConnectSmanInMsp",
    props: {
        deviceId: {
            type: Number,
            default: 0,
        },
        disabled: {
            type: Boolean,
            default: true,
        },
        hakId: {
            type: Number,
            default: 0,
        },
    },
    components: {
        ReSpinner,
    },
    data() {
        return {
            alertNoMspClientId: false,
            isLoadingStatusData: false,
            isLoadingActivationData: false,
            mspClientId: null,
            mspSmartmanager: null,
            smanActivateResp: null,
        };
    },
    computed: {
        compBtnActivateIsDisabled() {
            return (
                this.disabled ||
                this.isLoadingActivationData ||
                this.isLoadingStatusData ||
                !this.hakId ||
                !this.deviceId ||
                !this.mspSmartmanager
                // Dirk: leave the button always active //this.mspSmartmanager?.deviceStatus?.toLowerCase() !== "operational"
                // leave button enabled even if is already activated - this.compIsDeviceActivatedOnMsp
            );
        },
        compBtnRefreshStatusIsDisabled() {
            return (
                this.isLoadingStatusData ||
                this.isLoadingActivationData ||
                this.mspSmartmanager?.deviceStatus?.toLowerCase() === "operational"
            );
        },
        compIsDeviceActivatedOnMsp() {
            return this.smanActivateResp?.status === 200;
        },
    },
    methods: {
        onClickMspActivate() {
            this.isLoadingActivationData = true;
            mspAPI
                .activateSman({
                    hakID: this.hakId,
                    idSmartManager: this.mspClientId,
                })
                .then((resp) => {
                    this.smanActivateResp = resp;
                    console.log("smanActivateResp event", this.smanActivateResp);
                    this.$emit("smanActivateResp", this.smanActivateResp);
                })
                .catch(() => {
                    // something went wrong
                    this.smanActivateResp = { status: 0 };
                })
                .finally(() => {
                    this.isLoadingActivationData = false;
                });
        },
        loadMspSmartmanager() {
            this.alertNoMspClientId = false;
            this.isLoadingStatusData = true;
            devicesAPI
                .getSingle(this.deviceId)
                .then((resp) => {
                    this.mspClientId = resp.data.factoryNo;
                })
                .then(async () => {
                    if (this.mspClientId) {
                        await smartpowerAPI
                            .getSmanMinimal(this.mspClientId)
                            .then((resp) => {
                                this.mspSmartmanager = resp.data;
                                console.log("mspSmartmanager", this.mspSmartmanager);
                            })
                            .then(() => {
                                if (this.mspSmartmanager?.deviceStatus?.toLowerCase() === "operational") {
                                    this.smanActivateResp = { status: 200 };
                                    // emit event in order to enable Erledigt button
                                    this.$emit("smanActivateResp", this.smanActivateResp);
                                }
                                console.log("smanActivateResp", this.smanActivateResp);
                            });
                    } else {
                        this.alertNoMspClientId = true;
                    }
                })
                .finally(() => {
                    this.isLoadingStatusData = false;
                });
        },
    },
    watch: {
        deviceId: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.loadMspSmartmanager();
                }
            },
        },
    },
};
</script>
