<template>
    <Box headerText="Gerätestandort" :headerInfo="'#' + order?.auftragID" :isLoading="!order">
        <div v-if="order" class="d-flex">
            <div>
                <router-link :to="{ name: 'execution-single', params: { orderId: order.auftragID } }">
                    {{ order.hak.tp }}
                </router-link>
            </div>
            <div class="ml-auto font-weight-bold">
                {{ order.hak.address }}
            </div>
        </div>
    </Box>
</template>

<script>
export default {
    name: "ExecStepHeader",
    props: {
        order: {
            type: Object,
            default: null,
        },
    },
};
</script>
