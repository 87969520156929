var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Box',{attrs:{"headerText":_vm.actAuftragDetail?.action?.title,"isLoading":!_vm.actAuftragDetail || !_vm.order}},[(_vm.actAuftragDetail && _vm.order)?[_c('div',{staticClass:"mb-3"},[_c('ExecButtonPrevious',{attrs:{"navigateToStep":_vm.navigateToStepPrevious},on:{"clickPrevious":_vm.onClickPrevious}}),_c('ExecButtonNext',{staticClass:"float-right",attrs:{"navigateToStep":_vm.navigateToStepNext},on:{"clickNext":_vm.onClickNext}})],1),(!_vm.device || !_vm.auftragDetail712 || !_vm.auftragDetail714)?_c('b-spinner'):[(_vm.devicePool)?_c('exec-meter-info',{attrs:{"mainDevice":_vm.device,"order":_vm.order,"devicePool":_vm.devicePool,"category":'Wechseln'}}):_vm._e(),_c('exec-meter-reading-info',{staticClass:"mt-2",attrs:{"type":"uninstall","auftrag-detail712":_vm.auftragDetail712,"auftrag-detail714":_vm.auftragDetail714,"device":_vm.device}})],(_vm.emailObject)?_c('email-info-viewer',{attrs:{"emailObject":_vm.emailObject,"actAuftragDetail":_vm.actAuftragDetail}}):_vm._e(),(
                _vm.actAuftragDetail.status !== 202 &&
                    _vm.device &&
                    _vm.auftragDetail714 &&
                    _vm.auftragDetail712 &&
                    _vm.actAuftragDetail
            )?_c('exec-meter-reading-check',{attrs:{"device":_vm.device,"auftrag-detail714":_vm.auftragDetail714,"auftrag-detail712":_vm.auftragDetail712,"auftrag-detail-ausbau":_vm.actAuftragDetail},on:{"isReadingOk":_vm.onIsReadingOk}}):_vm._e(),(_vm.compIsStepAlreadyDone)?_c('b-alert',{staticClass:"mt-2",attrs:{"show":""}},[_vm._v("Zähler bereits ausgebaut. Bitte auf [Weiter] klicken.")]):_vm._e(),(_vm.device?.isBreaker)?_c('b-alert',{staticClass:"mt-2",attrs:{"show":""}},[_vm._v("Warnung: Der demontierte Zähler war ein Breakerzähler. Bitte ersetzen Sie diesen mit einen Smartmeter mit Breakerfunktion!")]):_vm._e(),(!_vm.isLoading && !_vm.busAddressValid)?_c('b-alert',{staticClass:"mt-2",attrs:{"show":""}},[_vm._v("Warnung: Der Zähler muss demontiert werden aufgrund einer BusAddress Kollision!")]):_vm._e(),_c('div',{staticClass:"d-flex mt-3"},[_c('b-button',{staticClass:"mr-3",attrs:{"disabled":_vm.compErledigtIsDisabled,"variant":"primary"},on:{"click":_vm.onClickRemoveNotPossible}},[_vm._v(" Ausbau nicht möglich ")]),_c('b-button',{staticClass:"ml-auto mr-3",attrs:{"disabled":_vm.compMeterStaysIsDisabled,"variant":"primary"},on:{"click":_vm.onClickMeterStays}},[_vm._v(" Bisheriger Zähler bleibt montiert ")]),_c('b-button',{staticClass:"ml-auto",attrs:{"disabled":_vm.compErledigtIsDisabled,"variant":"primary"},on:{"click":_vm.onClickRemoved}},[_vm._v(" Ausgebaut ")])],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }