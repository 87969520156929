import constants from "@/constants/constants";
import { httpClient } from "@/services/api/client";

const endpoint = "/net/rolloutview";

export default {
    // ********************
    // methods designed for datagrid
    // ********************
    getHaksForGridUrl() {
        return `${constants.env.API_ROOT_URL}${endpoint}/hak-view`;
    },

    getAllowedAuftragIdsSelectionForAuftragView(auftragStates, rolloutedStates) {
        const params = new URLSearchParams();
        auftragStates.map((auftragState) => params.append("auftragStates", auftragState));
        rolloutedStates.map((rolloutedState) => params.append("rolloutedStates", rolloutedState));
        return httpClient.get(`${constants.env.API_ROOT_URL}${endpoint}/auftrag-view/auftragIds`, {
            params: params,
        });
    },
};
