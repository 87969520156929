import { httpClient } from "@/services/api/client";
import moment from "moment";

const endpoint = "/net/devices";

export default {
    // ********************
    // default REST methods
    // ********************
    get(params) {
        return httpClient
            .get(endpoint, {
                params: params,
            })
            .then((resp) => {
                return resp.data;
            });
    },
    /**
     * Returns a promise
     * @param id
     * @param config
     * @returns a promise
     */
    getSingle(id, config = {}) {
        return httpClient.get(`${endpoint}/${id}`, {
            params: config,
        });
    },
    patch(id, payload) {
        return httpClient.patch(`${endpoint}/${id}`, payload);
    },
    post(payload) {
        return httpClient.post(endpoint, payload);
    },
    delete(id) {
        return httpClient.delete(`${endpoint}/${id}`);
    },
    // **************
    // custom methods
    // **************
    uninstall(deviceID) {
        const payloadDevices = [
            {
                op: "replace",
                path: "/toDate",
                value: moment()
                    .subtract(1, "days")
                    .format("YYYY-MM-DD"),
            },
        ];
        return this.patch(deviceID, payloadDevices);
    },
    createSman(device) {
        return httpClient.post(`${endpoint}/smartmanagers`, device);
    },
    getRelatedDevice(payload) {
        //If the payload includes the DeviceID of an old meter (to be removed in Step 7-2-0 or 7-2-1),
        //the response will contain the device information of the new meter (installed in Step 7-2-3).
        //For a DeviceID of a new meter, the old one will be returned.
        return httpClient.get(`${endpoint}/relatedDevice`, {
            params: payload,
        });
    },
    getRseInfo(auftragID, deviceID) {
        return httpClient.get(`${endpoint}/auftrag/${auftragID}/rse/${deviceID}`);
    },
    unmountRseNoReplacement(auftragID, deviceID) {
        return httpClient.put(`${endpoint}/auftrag/${auftragID}/rse/${deviceID}/unmount-no-replace`);
    },
    validateDeviceOldBusAddress(auftragId, deviceId) {
        return httpClient.get(endpoint + `/validate-device-old-bus-address/${auftragId}/${deviceId}`);
    },
    validateDeviceNewBusAddress(auftragId, deviceId) {
        return httpClient.get(endpoint + `/validate-device-new-bus-address/${auftragId}/${deviceId}`);
    },
};
