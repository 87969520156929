<template>
    <div v-if="data.msp === null" class="font-italic">
        <b-alert variant="danger" show>
            <b>SMAN in MSP nicht gefunden!</b><br />deviceId={{ data.mi.deviceSmartManager.deviceID }}
        </b-alert>
    </div>
    <div v-else>
        <b-form inline>
            <b-input-group append="min">
                <b-form-checkbox
                    v-model="mspState"
                    name="check-button"
                    :disabled="isSaving || disabled"
                    inline
                    switch
                    size="lg"
                    @change="onChangeStateToggle"
                >
                    <b-spinner v-if="isSaving" small />
                    <font-awesome-icon v-else :icon="['fas', 'check']" class="text-success" />
                </b-form-checkbox>

                <b-form-input v-model="minutes" :disabled="disabled" type="number" class="col-2" />
            </b-input-group>
        </b-form>
        <div v-if="errorCallingMspRelaisTestSwitch" class="d-flex text-danger">
            <span><font-awesome-icon :icon="['fas', 'exclamation-triangle']"/></span>
            <div class="ml-1">Es ist ein Fehler aufgetreten.</div>
        </div>
    </div>
</template>

<script>
import mspAPI from "@/services/api/msp.api";

export default {
    name: "MspRelaisTest",
    props: {
        data: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            errorCallingMspRelaisTestSwitch: false,
            isSaving: false,
            minutes: 5,
            mspClientId: null,
            mspRelaisPort: null,
            mspState: false,
        };
    },
    async mounted() {
        if (this.data.msp) {
            this.mspRelaisPort = this.data.msp.switches[this.data.index].relayPort;
            this.mspState = this.data.msp.switches[this.data.index].buttonState === "Off" ? false : true;
            this.mspClientId = this.data.msp.mgrId;
        }
    },
    methods: {
        async onChangeStateToggle() {
            // clear error (in case it was active)
            this.errorCallingMspRelaisTestSwitch = false;

            this.isSaving = true;
            mspAPI
                .postTestSwitch({
                    idSmartManager: this.mspClientId,
                    relayPort: this.mspRelaisPort,
                    buttonState: this.mspState,
                    minutes: this.minutes,
                })
                .catch(() => {
                    // something went wrong -> change switch to the state it was before
                    this.mspState = !this.mspState;
                    this.errorCallingMspRelaisTestSwitch = true;
                })
                .finally(() => {
                    this.isSaving = false;
                });
        },
    },
};
</script>
