<template>
    <div>
        <h3>Installationsanleitung</h3>
        <div>Diese Seite erklärt Schritt für Schritt die Installation und Konfiguration der Apps.</div>

        <h4 class="mt-5">{{ getBridgeAppName }}</h4>
        <ul class="mt-2">
            <li>
                Bitte die REtasks Bridge App für Android Smartphone/Tablet herunterladen und installieren:
                <div>
                    <img height="48" src="@/assets/bridge-app.png" alt="REtasks Bridge app" />
                    <strong class="ml-2">
                        <a :href="getApkDownloadUrl">
                            {{ getBridgeAppName }}
                        </a>
                    </strong>
                </div>
            </li>
            <li class="mt-3">
                Anschliessend durch langes Drücken auf das REtasks Bridge Symbol in die App-Info navigieren:
                <div><img width="250" src="@/assets/help/bridge-help1.jpg" alt="REtasks Bridge icon" /></div>
            </li>
            <li class="mt-3">
                In der App-Info auf <strong>Festlegen als Standard</strong> tippen:
                <div>
                    <img width="250" src="@/assets/help/bridge-help2.jpg" alt="REtasks Bridge: App-Info settings" />
                </div>
            </li>
            <li class="mt-3">
                <strong>Unterstützte Links öffnen</strong> aktivieren:
                <div>
                    <img width="250" src="@/assets/help/bridge-help3.jpg" alt="REtasks Bridge: allow open link" />
                </div>
            </li>
            <li class="mt-3">
                Und auf <strong>Unterstützte Webadressen</strong> tippen. Auf diesem Bildschirm alle Optionen
                aktivieren:
                <div>
                    <img width="250" src="@/assets/help/bridge-help4.jpg" alt="REtasks Bridge: allow open link" />
                </div>
            </li>
        </ul>
        <h4 class="mt-5">Konfigurationsdatei</h4>
        <ul class="mt-2">
            <li>
                Bitte die folgende Konfigurationsdatei herunterladen:
                <div>
                    <font-awesome-icon :icon="['fas', 'file-download']" size="xl" />
                    <strong class="ml-2">
                        <a href="#" title="Download" @click.prevent="downloadUnimodReaderInstallConfigFile">
                            Konfigurationsdatei
                        </a>
                    </strong>
                </div>
            </li>
        </ul>
        <h4 class="mt-5">UNIMOD Reader</h4>
        <ul class="mt-2">
            <li>
                Bitte die UNIMOD Reader App für Android Smartphone/Tablet installieren:
                <div>
                    <img height="48" src="@/assets/unimod-app.png" alt="Unimod Reader app" />
                    <strong class="ml-2">
                        <a
                            href="https://play.google.com/store/apps/details?id=ch.volag.ureader&hl=de_CH"
                            target="_blank"
                        >
                            UNIMOD reader
                            <sup><font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']"/></sup>
                        </a>
                    </strong>
                </div>
            </li>
            <li>
                Nach dem 1. Start der UNIMOD reader App wird nach einer Konfigurationsdatei gefragt.<br />
                Bitte die Datei auswählen, welche im vorherigen Schritt heruntergeladen wurde.
            </li>
        </ul>
    </div>
</template>

<script>
import constants from "@/constants/constants";
import assetAPI from "@/services/api/assets.api";

export default {
    name: "Bridge",
    computed: {
        getApkDownloadUrl() {
            if (constants.env.NAME === "production") {
                return "/REtasksBridge.apk";
            } else if (constants.env.NAME === "staging") {
                return "/REtasksBridge_test.apk";
            }
            return "/REtasksBridge_develop.apk";
        },
        getBridgeAppName() {
            if (constants.env.NAME === "production") {
                return "REtasks Bridge";
            } else if (constants.env.NAME === "staging") {
                return "REtasks Bridge TEST";
            }
            return "REtasks Bridge DEV";
        },
    },
    methods: {
        downloadUnimodReaderInstallConfigFile() {
            const unimodReaderInstallConfigFileName = "unimodReaderInstallConfig.bin";
            const fixedIdForConfigFile = -1;

            assetAPI.downloadAsset(fixedIdForConfigFile, { responseType: "blob" }).then((response) => {
                const blob = new Blob([response.data]);
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = unimodReaderInstallConfigFileName;
                link.click();
                URL.revokeObjectURL(link.href);
            });
        },
    },
};
</script>

<style scoped></style>
