<template>
    <div>
        <h3 class="pb-3 mt-2 fw-bold">Einstellungen bearbeiten</h3>
        <div class="settings-container">
            <div v-for="(setting, index) in applicationSetting" :key="index" class="col-4 settings-pair">
                <label
                    class="settings-label"
                    :for="`setting-${index}`"
                    @click="focusInput(`setting-${index}`, index)"
                    :title="setting.details"
                >
                    {{ transform(setting.name) }}
                </label>
                <div style="flex: 1">
                    <template v-if="isBoolean(setting.value)">
                        <DxCheckBox
                            v-model="setting.value"
                            class="settings-value"
                            :id="`setting-${index}`"
                            :focus-state-enabled="true"
                        />
                    </template>
                    <template v-else>
                        <DxTextBox
                            v-model="setting.value"
                            class="settings-value"
                            :id="`setting-${index}`"
                            :focus-state-enabled="true"
                            v-bind:ref="`textBox-${index}`"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Dieses Feld muss ausgefüllt sein." />
                            </DxValidator>
                        </DxTextBox>
                    </template>
                </div>
            </div>
        </div>
        <div class="save-button">
            <DxButton
                text="Speichern"
                type="danger"
                class="ml-2 float-right"
                @click="onClickSave"
                :disabled="isSaveDisabled"
                :style="{ opacity: isSaveDisabled ? 0.4 : 1 }"
            />
        </div>
    </div>
</template>

<script>
import applicationSettingAPI from "@/services/api/applicationSetting.api";
import { DxButton } from "devextreme-vue";
import { DxTextBox } from "devextreme-vue/text-box";
import { DxCheckBox } from "devextreme-vue/check-box";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";
import constants from "@/constants/constants";
import { mapGetters } from "vuex";

export default {
    name: "Administration",
    components: {
        DxCheckBox,
        DxButton,
        DxTextBox,
        DxValidator,
        DxRequiredRule,
    },

    data() {
        return {
            applicationSetting: [],
            constants: constants,
        };
    },

    computed: {
        ...mapGetters(["currentUser"]),

        isSaveDisabled() {
            const hasChanges = this.applicationSetting.some((setting) => setting.originalValue !== setting.value);
            const hasEmptyFields = this.applicationSetting.some(
                (setting) => !setting.value && !this.isBoolean(setting.value)
            );
            return !hasChanges || hasEmptyFields;
        },
    },

    watch: {
        currentUser: {
            immediate: true,
            handler(newVal) {
                if (newVal && newVal.userRoles && !this.userRolesFetched) {
                    this.userRolesFetched = true;
                    if (this.doesUserHaveRole(this.constants.ADMIN_ROLE_ID)) {
                        this.fetchSettings();
                    } else {
                        this.$router.replace({ name: "unauthorized", query: { reason: "role" } });
                    }
                }
            },
        },
    },
    methods: {
        isBoolean(value) {
            return value === true || value === false;
        },

        async onClickSave() {
            try {
                const updateSettingPromises = this.applicationSetting
                    .filter((setting) => setting.originalValue !== setting.value)
                    .map((setting) => {
                        setting.originalValue = setting.value;
                        return applicationSettingAPI.put(setting.name, {
                            name: setting.name,
                            value: setting.value.toString(),
                        });
                    });

                const responses = await Promise.all(updateSettingPromises);

                const allSuccess = responses.every((response) => response.status === 204);

                if (allSuccess) {
                    this.$bvToast.toast("Die Einstellungen wurden erfolgreich gespeichert.", {
                        title: "Erfolgreich",
                        variant: "success",
                        toaster: "b-toaster-bottom-right",
                        noAutoHide: false,
                        appendToast: true,
                    });
                }
            } catch (error) {
                this.$bvToast.toast("Die Einstellungen konnten nicht gespeichert werden.", {
                    title: "Fehler",
                    variant: "danger",
                    toaster: "b-toaster-bottom-right",
                    noAutoHide: false,
                    appendToast: true,
                });
            }
        },

        async fetchSettings() {
            try {
                const response = await applicationSettingAPI.getAll();
                this.applicationSetting = response.data.map((setting) => ({
                    ...setting,
                    originalValue: this.parseValue(setting.value),
                    value: this.parseValue(setting.value),
                }));
            } catch (error) {
                console.error("Fehler beim Laden der Daten");
            }
        },

        parseValue(value) {
            if (value === "true") return true;
            if (value === "false") return false;
            return value;
        },

        doesUserHaveRole(roleID) {
            if (!this.currentUser) return false;
            return this.currentUser.userRoles.some((userRole) => userRole.roleID === roleID);
        },

        transform(value) {
            return value.replace(/([A-Z])/g, " $1");
        },

        focusInput(inputId, index) {
            const input = document.getElementById(inputId);
            if (input) {
                input.focus();
            }

            this.$nextTick(() => {
                const textBox = this.$refs[`textBox-${index}`];
                if (textBox && textBox[0]) {
                    const textBoxInput = textBox[0].$el.querySelector("input");
                    if (textBoxInput) {
                        textBoxInput.focus();
                    }
                }
            });
        },
    },
};
</script>

<style scoped>
.settings-label {
    flex: 1;
    margin-bottom: 5px;
    margin-right: 15px;
}

.settings-value {
    flex: 1;
}

.settings-pair {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
    flex: 1 1 calc(33.333% - 20px);
}

.settings-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.save-button {
    text-align: right;
    margin-top: 10px;
}

@media screen and (max-width: 992px) {
    .settings-pair {
        flex: 1 1 calc(50% - 20px);
        margin-bottom: 20px;
    }

    .settings-container {
        gap: 50px;
    }

    .settings-value,
    .settings-label {
        width: 100%;
        min-width: 250px;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 764px) {
    .settings-pair {
        flex: 1 1 100%;
        margin-bottom: 20px;
    }

    .settings-container {
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
    }

    .settings-label,
    .settings-value {
        width: 100%;
    }

    .save-button {
        text-align: right;
        width: 100%;
    }
}
</style>
