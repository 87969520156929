import { httpClient } from "@/services/api/client";

const endpoint = "/net/auftrags";

export default {
    // ********************
    // default REST methods
    // ********************
    get(params) {
        return httpClient
            .get(endpoint, {
                params: params,
            })
            .then((resp) => {
                return resp.data;
            });
    },
    getSingle(id, params = {}) {
        return httpClient.get(`${endpoint}/${id}`, { params: params });
    },
    patch(id, payload) {
        return httpClient.patch(`${endpoint}/${id}`, payload).then((resp) => {
            return resp.data;
        });
    },
    // **************
    // custom methods
    // **************
    getByTechnicianId(id) {
        return this.get({ technicianid: id }).then((resp) => {
            return resp;
        });
    },
    postPhase(auftragSyncQueueID, phase) {
        return httpClient.post(`${endpoint}/phase/${phase}/`, null, {
            params: { auftragSyncQueueID: auftragSyncQueueID },
        });
    },
    bulkPatch(payload) {
        return httpClient.patch(`${endpoint}/bulk`, payload).then((resp) => {
            return resp.data;
        });
    },
    bulkDelete(data) {
        return httpClient
            .delete(`${endpoint}/bulk`, {
                data: data,
            })
            .then((resp) => {
                return resp.data;
            });
    },
    dataImportStart(auftragId) {
        // note: squeezed this endpoint in here because it is closely related to "auftrag" entity (did not want to create a dedicated service file for it)
        // POST api/net/auftrag-data-import/{Auftrag.AuftragID}
        //   ToDo(clarify): move this endpoint to /api/net/auftrags/data-import/{auftragId}
        // starts a hangfire job which triggers the data import for the given auftrag (ETL480)
        return httpClient.post(`net/auftrag-data-import/${auftragId}`);
    },
};
