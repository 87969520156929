import { httpClient } from "@/services/api/client";

const endpoint = "/net/device-relais";

export default {
    // ********************
    // default REST methods
    // ********************
    get(params) {
        return httpClient.get(endpoint, { params: params });
    },
    getSingle(id, params) {
        return httpClient.get(`${endpoint}/${id}`, params);
    },
    patch(id, payload) {
        return httpClient.patch(`${endpoint}/${id}`, payload);
    },
    // **************
    // Update Device Relais in KeyValueOptions and also in MSP (switch configuration)
    // **************
    updateDeviceRelaisAndMspSwitchConfiguration(id, payload) {
        return httpClient.put(`${endpoint}/${id}`, payload);
    },
};
