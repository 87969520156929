<template>
    <div class="pb-2">
        <b-alert
            v-if="compValidationsOK.length || compValidationsNOK.length"
            class="mb-2 px-2 py-1 font-italic small"
            variant="warning"
            show
        >
            <div class="font-weight-bold">
                PhotoUpload ist nicht verfügbar:
            </div>
            <div v-show="compValidationsOK.length" class="mt-1">
                <!-- hide OK label (until they are used) -->
                <div v-if="false" class="font-weight-bold">OK:</div>
                <ul class="pl-3 mb-0">
                    <li v-for="(v, i) in compValidationsOK" :key="i">
                        {{ v.message }}
                        <span v-if="v?.comment?.length" class="small text-secondary font-italic">
                            <br />
                            <font-awesome-icon :icon="['fas', 'info-circle']" />
                            {{ v.comment }}
                        </span>
                    </li>
                </ul>
            </div>
            <div v-show="compValidationsNOK.length" class="mt-1">
                <!-- hide NOK label (until OKs are used) -->
                <div v-if="false" class="font-weight-bold">NOK:</div>
                <ul class="pl-3 mb-0">
                    <li v-for="(v, i) in compValidationsNOK" :key="i">
                        {{ v.message }}
                        <span v-if="v?.comment?.length" class="small text-secondary">
                            <br />
                            <font-awesome-icon :icon="['fas', 'info-circle']" />
                            {{ v.comment }}
                        </span>
                    </li>
                </ul>
            </div>
        </b-alert>
        <div class="d-flex flex-row justify-content-between">
            <div class="d-lg-none" v-for="captureType in captureTypes" :key="captureType">
                <file-upload
                    :input-id="refEntityKey + captureType"
                    class="btn btn-primary"
                    extensions="jpg,jpeg,png"
                    accept="image/png,image/jpeg"
                    :multiple="false"
                    :size="1024 * 1024 * 30"
                    ref="upload"
                    :class="{ disabled: compValidationsNOK.length > 0 }"
                    :disabled="compValidationsNOK.length > 0"
                    @input-filter="inputFilter"
                    :capture="captureType === 'camera' ? 'environment' : undefined"
                    @input-file="onStartUpload"
                >
                    <span v-if="captureType === 'camera'">
                        <font-awesome-icon :icon="['fas', 'camera']" size="lg" class="pl-1 pr-1" />
                        {{ showLabelForCaptureTypes ? "Kamera" : "" }}
                    </span>
                    <span v-else>
                        <font-awesome-icon :icon="['fas', 'plus']" size="lg" class="pl-1 pr-1" />
                        {{ showLabelForCaptureTypes ? "Fotogalerie" : "" }}
                    </span>
                </file-upload>
            </div>
            <div class="d-none d-lg-block">
                <file-upload
                    :input-id="refEntityKey"
                    class="btn btn-primary"
                    extensions="jpg,jpeg,png"
                    accept="image/png,image/jpeg"
                    :multiple="false"
                    :size="1024 * 1024 * 30"
                    ref="upload"
                    :class="{ disabled: compValidationsNOK.length > 0 }"
                    :disabled="compValidationsNOK.length > 0"
                    @input-filter="inputFilter"
                    @input-file="onStartUpload"
                >
                    <span> <font-awesome-icon :icon="['fas', 'plus']" size="lg" /> Datei hochladen </span>
                </file-upload>
            </div>
        </div>
        <template v-if="isCurrentStepEqualToEndingStep">
            <b-progress class="mt-2" show-value :max="maxProgressBarValue">
                <b-progress-bar
                    :value="progressBarValue"
                    :variant="progressBarVariant"
                    v-if="isProgressBarUpdated"
                    :animated="isAnimated"
                >
                    <span>
                        <strong>{{ currentUploadPercentage }}</strong>
                    </span>
                </b-progress-bar>
            </b-progress>
            <template v-if="isUploadInProgress">
                <small> {{ currentUploadProgress }} </small>
            </template>
            <ImageUploadView
                :imageList="localImages"
                :isUploadCurrentlyInProgress="isUploadCurrentlyInProgress"
                @forceUpload="onClickForceUpload"
                v-if="localImages.length"
            />
        </template>
        <b-spinner v-if="assetsItemsIsLoading" />
        <template v-else-if="showImages">
            <ImageGallery
                :imageList="images"
                :isAssetDeletable="assetDelete"
                :key="refEntityKey"
                :isDisabled="compValidationsNOK.length > 0"
                :isUploadCurrentlyInProgress="isUploadCurrentlyInProgress"
                @assetIsUploaded="onClickManualUpload"
                @assetIsDeleted="onClickDeleteAsset"
                @animationStatus="onUpdateAnimationStatus"
            />
        </template>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import FileUpload from "vue-upload-component";

import constants from "@/constants/constants";
import ImageGallery from "@/components/general/ImageGallery.vue";
import ImageUploadView from "@/components/general/ImageUploadView.vue";
import cacheService from "@/services/cacheService";
import devicepoolsApi from "@/services/api/devicepools.api";
import assetsApi from "@/services/api/assets.api";
import { eventBus } from "@/utils/eventBus";
import auftragsApi from "@/services/api/auftrags.api";
import auftragDetailsApi from "@/services/api/auftragDetails.api";
import { isDesktop } from "@/utils/energyspace/ScreenHelper";

import _ from "lodash";

export default {
    name: "PhotoUpload",
    components: {
        FileUpload,
        ImageGallery,
        ImageUploadView,
    },
    props: {
        auftragDetail: {
            type: Object,
            required: true,
        },
        // ToDo(clarify): is this really needed?
        //   note: the component was always used with "assetDelete=true" (so I set it to "default: true" and removed it from component inputs)
        // option which enables delete functionality of an asset
        assetDelete: {
            type: Boolean,
            default: true,
        },
        captureTypes: {
            type: Array,
            defaults: [""],
        },
        entity: {
            type: String,
            required: true,
        },
        entityId: {
            type: Number,
            required: true,
        },
        fileDescription: {
            type: String,
            default: "",
        },
        folder: {
            type: String,
            required: true,
        },
        refEntity: {
            type: String,
            default: "",
        },
        refEntityId: {
            type: Number,
            default: 0,
        },
        refEntityKey: {
            type: String,
            default: null,
        },
        // ToDo(clarify): is this really needed?
        //   note: the component was always used with "showImages=true" (so I set it to "default: true" and removed it from component inputs)
        showImages: {
            type: Boolean,
            default: true,
        },
        // ToDo(clarify): is this really needed?
        //   note: the component was always used with "showLabelForCaptureTypes=true" (so I set it to "default: true" and removed it from component inputs)
        showLabelForCaptureTypes: {
            type: Boolean,
            default: true,
        },
        downloadable: {
            type: Boolean,
            default: false,
        },
        // ToDo(clarify): what's the purpose of this prop? -> setting it to "true" does not do anything...
        // ToDo(clarify): is this really needed?
        //   note: the component was always used with "isDisabled=true", except in step 5-1-1 and 7-3-1 (so I set it to "default: false" and removed it from component inputs where it was passed with "false")
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isDisabledDetails: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            app: "rellout",
            isProgressBarUpdated: false,
            isAnimated: false,
            assetsItemsIsLoading: false,
            assetsItems: [],
            images: [],
            localImages: [],
            currentUploadedPhotos: 0,
            totalPhotos: 0,
            hakId: 0,
            auftragStatusID: null,
            orderId: this.$route.params?.orderId ?? null,
            deviceAssetIds: [], // Asset ID of devices (steps 5-1-1, 7-2-1) - Populated in step 9-1-1 only
        };
    },
    created() {
        // adds the event listener that handles the photoUpdate event
        eventBus.$on("photoUpdate", async (value) => {
            await this.handleAssetUpload(value);
            this.reloadArrays();
        });
        eventBus.$on("photoSkipped", async (id) => {
            this.skipArrayItem(this.images, id);
            this.reloadArrays();
        });
    },
    async mounted() {
        if (this.isCurrentStepEqualToEndingStep) {
            // ToDo(clarify): this is a hacky assumption... -> can we do better? what is the purpose of doing this?
            // hakId will be 0 if the current step is not 9-1-1
            const hakResponse = await devicepoolsApi.getSingle(this.entityId);
            this.hakId = hakResponse.data.hakID;
        }

        if (this.orderId) {
            const orderResponse = await auftragsApi.getSingle(this.orderId);
            this.auftragStatusID = orderResponse.data.auftragStatusID;
        }

        await this.updateProgressBarAndAssets();
    },
    computed: {
        ...mapGetters({
            oidcAccessToken: "oidcAccessToken",
        }),
        compValidations() {
            /**
             * the "SPOT" when it comes to validations (BUSINESS and(!) TECHNICAL)
             * SPOT - Single Point Of Truth
             */
            const _validations = [];

            // TECHNICAL validation checks
            //  note: minimum checks to ensure robust component functionality
            // prop auftragDetail
            if (Object.keys(this.auftragDetail).length === 0) {
                _validations.push({
                    status: "NOK",
                    message: "prop 'auftragDetail': value is empty!",
                    comment: "please pass the auftragDetail record of the step you are using this component in.",
                });
            } else {
                // auftragDetail.stepCurrent
                const _stepCurrent = this.auftragDetail.stepCurrent;
                if (_stepCurrent === undefined) {
                    _validations.push({
                        status: "NOK",
                        message: "auftragDetail.stepCurrent: key not passed!",
                        comment: "please check.",
                    });
                } else {
                    if (_stepCurrent === "") {
                        _validations.push({
                            status: "NOK",
                            message: "auftragDetail.stepCurrent: value is empty!",
                            comment: "please check.",
                        });
                    }
                }
            }

            // BUSINESS validation checks
            // note: order checks by importance (so they will be displayed in that order, in case multiple checks apply)
            if (this.auftragDetail.isExecutable === false) {
                _validations.push({
                    status: "NOK",
                    message: "Schritt ist nicht ausführbar!",
                    comment: this.auftragDetail.isExecutableDetails,
                });
            }
            if (this.isDisabled === true) {
                _validations.push({
                    status: "NOK",
                    message: `Komponente deaktiviert (isDisabled=${this.isDisabled})`,
                    comment: this.isDisabledDetails,
                });
            }

            return _validations;
        },
        compValidationsOK() {
            // note: OKs are currently not used -> we only display NOKs (still implemented for completeness)
            return this.compValidations.filter((v) => v.status.toUpperCase() === "OK");
        },
        compValidationsNOK() {
            return this.compValidations.filter((v) => v.status.toUpperCase() === "NOK");
        },
        isUploadCurrentlyInProgress() {
            return this.isAnimated;
        },
        uploadPercentage() {
            return `${Math.round((this.currentUploadedPhotos / this.totalPhotos) * 100)}%`;
        },
        progressBarValue() {
            const { FULL_PROGRESS_BAR } = constants.upload;
            return this.isProgressBarFull
                ? FULL_PROGRESS_BAR
                : this.currentUploadedPhotos > 0
                ? this.currentUploadedPhotos
                : FULL_PROGRESS_BAR;
        },
        maxProgressBarValue() {
            const { FULL_PROGRESS_BAR } = constants.upload;
            return this.isProgressBarFull ? FULL_PROGRESS_BAR : this.totalPhotos;
        },
        progressBarVariant() {
            return this.isProgressBarFull ? "success" : this.currentUploadedPhotos ? "" : "warning";
        },
        currentUploadPercentage() {
            return this.isProgressBarFull
                ? `${this.uploadPercentage} hochgeladen`
                : this.currentUploadedPhotos > 0
                ? this.uploadPercentage
                : "Keine Fotos hochgeladen";
        },
        currentUploadProgress() {
            return `Insgesamt ${this.currentUploadedPhotos} / ${this.totalPhotos} Fotos hochgeladen`;
        },
        // ToDo(code-cleanup): refactor this to be less hacky
        isCurrentStepEqualToEndingStep() {
            return this.auftragDetail.stepCurrent === "9-1-1";
        },
        isUploadCompleted() {
            const isUploadCompleted = _.isEqual(this.currentUploadedPhotos, this.totalPhotos);
            this.$emit("uploadCompletionStatus", isUploadCompleted);
            return isUploadCompleted;
        },
        isProgressBarFull() {
            return this.currentUploadedPhotos > 0 && this.isUploadCompleted;
        },
        isUploadInProgress() {
            return !this.isUploadCompleted && this.currentUploadedPhotos > 0;
        },
    },
    methods: {
        async onClickForceUpload(status) {
            await this.handleForceUpload(status);
            this.reloadArrays();
        },
        onUpdateAnimationStatus(status) {
            this.isAnimated = status;
        },
        async onClickDeleteAsset(value) {
            await this.handleAssetDeleteById(value);
        },
        async onClickManualUpload(value) {
            this.isAnimated = false;
            await this.handleAssetUpload(value);
            this.reloadArrays();
        },
        reloadArrays() {
            this.images = [...this.images];
            this.localImages = [...this.localImages];
        },
        async onStartUpload(file) {
            await this.saveFile(file);
        },
        findAssetInListById(array, id) {
            const index = array.findIndex((img) => img.id === id);
            return index !== -1 ? index : null;
        },
        removeAssetFromListById(array, id) {
            try {
                const index = this.findAssetInListById(array, id);
                if (index >= 0) {
                    array.splice(index, 1);
                }
                return !!index;
            } catch {
                return false;
            }
        },
        async handleAssetDeleteById(id) {
            this.removeAssetFromListById(this.localImages, id);
            this.removeAssetFromListById(this.images, id);
            await this.updateProgressBarAndAssets();
            this.$emit("assetDeleted", { assetId: id });
        },
        skipArrayItem(array, id) {
            const index = this.findAssetInListById(array, id);
            if (index !== -1) {
                array[index].isUploadSkipped = true;
            }
        },
        updateArrayItem(array, photo) {
            const index = this.findAssetInListById(array, photo.id);
            if (index !== -1) {
                array[index] = cacheService.updatePhoto(array[index], photo.img ?? photo);
            }
        },
        async handleAssetUpload(photo) {
            this.updateArrayItem(this.images, photo);
            this.removeAssetFromListById(this.localImages, photo.id);
            await this.updateProgressBarAndAssets();
        },
        createAssetsPayload(filename) {
            return {
                auftragId: this.orderId,
                description: this.fileDescription,
                entity: this.entity,
                entityId: this.entityId,
                isUploaded: false,
                filename: filename,
                ...(this.refEntity &&
                    this.refEntityId &&
                    this.refEntityId > 0 && {
                        refEntity: this.refEntity,
                        refEntityId: this.refEntityId,
                        refEntityKey: this.refEntityKey,
                    }),
            };
        },
        async createImageURL(file) {
            const blob = new Blob([file.file], { type: "application/octet-stream" });
            const imageURL = await cacheService.convertBlobToImageURL(blob);
            return imageURL;
        },
        async savePhotoLocally(imageData, path) {
            const { assetsPayload, asset } = imageData;
            const createdPhoto = await cacheService.savePhoto(
                {
                    folder: this.folder,
                    assetsPayload,
                    ...asset,
                    path,
                    isUploadSkipped: false,
                },
                asset.id
            );
            return createdPhoto;
        },
        async saveImageLocallyAndPrepareForImageArray(imageData, path) {
            this.savePhotoLocally(imageData, path);
        },
        async saveFile(file) {
            try {
                if (!file) return;
                const filename = file.file.name;
                const assetsPayload = this.createAssetsPayload(filename);
                const imageUrl = await this.createImageURL(file);
                const response = await cacheService.uploadFile(assetsPayload);
                const asset = response.data;
                if (this.isAssetTypeCorrect(asset?.type)) {
                    await this.saveImageLocallyAndPrepareForImageArray(
                        {
                            folder: this.folder,
                            assetsPayload,
                            asset,
                        },
                        imageUrl
                    );
                }
                if (isDesktop()) {
                    cacheService.uploadOldestPhoto();
                }
                await this.updateProgressBarAndAssets();
            } catch (err) {
                console.error(err);
                this.displayToast("danger", "Fehler beim Speichern des Fotos");
            }
        },
        async handleForceUpload(status) {
            this.isAnimated = status;
            const uploadPromises = this.localImages.map((asset) =>
                cacheService
                    .uploadSinglePhotoAndHandleError(asset)
                    .then((resp) => {
                        eventBus.$emit("photoUpdate", resp);
                    })
                    .catch((err) => {
                        this.displayToast("danger", err);
                    })
            );

            await Promise.all(uploadPromises);
            this.isAnimated = !status;
        },
        async updateProgressBarAndAssets() {
            try {
                this.assetsItemsIsLoading = true;
                // rewritten due to race conditions #19601
                const devicePoolAssetsResponse = await assetsApi.get({
                    app: this.app,
                    // ToDo(#20470): remove "isDeleted" when implemented
                    isDeleted: false,
                    entity: this.entity,
                    entityId: this.entityId,
                });
                let assets = [...devicePoolAssetsResponse.data];

                if (this.isCurrentStepEqualToEndingStep) {
                    // used to get hak-images to track progress using progressbar on 9-1-1
                    const hakAssetsResponse = await assetsApi.get({
                        app: this.app,
                        // ToDo(#20470): remove "isDeleted" when implemented
                        isDeleted: false,
                        entity: "hak",
                        entityId: this.hakId,
                    });
                    assets = [...assets, ...hakAssetsResponse.data];
                    // Get Device related Assets
                    const devicePoolID = this.entityId; // true when step 9-1-1
                    // get Assets of steps 7-1-3 and 5-1-1
                    const steps713 = await auftragDetailsApi.getByOrderIdAndStep(
                        this.orderId,
                        "7-1-3",
                        devicePoolID,
                        null,
                        null,
                        true
                    );
                    const steps511 = await auftragDetailsApi.getByOrderIdAndStep(
                        this.orderId,
                        "5-1-1",
                        devicePoolID,
                        null,
                        null,
                        true
                    );
                    let stepAssetPromises = [];
                    for (const step of [...steps713, ...steps511]) {
                        if (!this.deviceAssetIds.includes(step.deviceID)) {
                            // avoid add 2 times the same device in case it has mutliple images
                            stepAssetPromises.push(
                                assetsApi.get({
                                    app: this.app,
                                    // ToDo(#20470): remove "isDeleted" when implemented
                                    isDeleted: false,
                                    entity: "device",
                                    entityId: step.deviceID,
                                })
                            );
                        }
                    }
                    const deviceAssetsResponse = await Promise.all(stepAssetPromises);
                    for (const deviceAssetResponse of deviceAssetsResponse) {
                        assets = [...assets, ...deviceAssetResponse.data];
                        deviceAssetResponse.data.forEach((asset) => this.deviceAssetIds.push(asset.id));
                    }
                }
                await this.updateAssets(assets);
                await this.updateProgressBar(assets);
                this.assetsItems = assets;
            } catch (e) {
                console.error(e);
                throw new Error("Fehler beim Aktualisieren der Progressbar und Assets");
            } finally {
                this.assetsItemsIsLoading = false;
                this.$emit("assetItems", { images: this.images });
            }
        },
        async updateAssets(data) {
            this.images = [];
            for (const asset of data) {
                if (!this.isAssetTypeCorrect(asset.type)) continue;

                if (!this.refEntity || !this.refEntityId) continue;

                if (
                    asset.refEntity === this.refEntity &&
                    Number(asset.refEntityId) === this.refEntityId &&
                    this.compareValuesIfRefEntityKeyTruthy(asset.refEntityKey, this.refEntityKey)
                ) {
                    await this.addAssetToArray(asset);
                }
            }
        },
        compareValuesIfRefEntityKeyTruthy(val1, val2) {
            return val2 ? val1 === val2 : true;
        },
        async updateProgressBar(assets) {
            this.currentUploadedPhotos = assets.filter((asset) => asset.isUploaded).length;
            this.totalPhotos = assets.length;
            this.isProgressBarUpdated = true;
            await this.loadCacheIntoLocalImages();
        },
        async loadCacheIntoLocalImages() {
            // Load Hak Images
            const localHaksPromise = cacheService.getPhotosByFilter({
                entity: "hak",
                entityId: this.hakId, // hakID
            });
            // Load DevicePool images
            const localDevicePoolsPromise = cacheService.getPhotosByFilter({
                entity: "devicepool",
                entityId: this.entityId, // devicePoolID
            });
            const [localDevicePoolResponse, localHakResponse] = await Promise.all([
                localDevicePoolsPromise,
                localHaksPromise,
            ]);
            // Load Device images
            const localDevicePromises = [];
            let localDeviceResponses = [];
            if (this.deviceAssetIds.length > 0) {
                for (const assetID of this.deviceAssetIds) {
                    const cachedDeviceImage = await cacheService.getPhotoByID(assetID);
                    if (cachedDeviceImage) localDevicePromises.push(cachedDeviceImage);
                }
                localDeviceResponses = await Promise.all(localDevicePromises);
                localDeviceResponses = localDeviceResponses.flat(); // flatten localDeviceResponses because is an array of array
            }
            this.localImages = [...localDevicePoolResponse, ...localHakResponse, ...localDeviceResponses];
        },
        async addAssetToArray(asset) {
            const image = await cacheService.getPhotoByID(asset.id);
            const modifiedImage = image ? { id: asset.id, ...this.getModifiedImage(image) } : null;
            this.images.push(modifiedImage ?? asset);
        },
        isAssetTypeCorrect(type) {
            return type === constants.image.assetType.IMAGE;
        },
        getModifiedImage(img) {
            delete img.payload;
            delete img.assetsPayload;
            return img;
        },

        // oldFile must stay, it is injected by the control
        inputFilter(newFile, oldFile, prevent) {
            // show error if non-image file
            if (!/\.(gif|jpg|jpeg|png)$/i.test(newFile.name)) {
                this.$bvToast.toast(
                    "Die ausgewählte Datei ist leider nicht gültig. Gültige Dateiendungen: *.jpg, *.jpeg, *.png",
                    {
                        title: "Fehler",
                        variant: "danger",
                        toaster: "b-toaster-bottom-right",
                        noAutoHide: true,
                        appendToast: true,
                    }
                );
                return prevent();
            }
        },
        displayToast(variant = "INFO", message) {
            let noAutoHide = false;
            let title;
            switch (variant.toUpperCase()) {
                case "DANGER":
                    noAutoHide = true;
                    title = "Fehler";
                    break;
                case "INFO":
                    title = "INFO";
                    break;
                case "SUCCESS":
                    title = "SUCCESS";
                    break;
                default:
                    variant = "info";
                    title = "INFO";
            }
            this.$bvToast.toast(`${message}`, {
                title: title,
                variant: variant.toLowerCase(),
                toaster: "b-toaster-bottom-right",
                noAutoHide: noAutoHide,
                appendToast: true,
            });
        },
    },
    beforeDestroy() {
        eventBus.$off("photoUpdate", "photoSkipped");
    },
};
</script>
