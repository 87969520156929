var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Main',[_c('Box',{attrs:{"headerText":"Gerätestandort","headerInfo":'#' + _vm.order?.auftragID,"isLoading":!_vm.devicePool || !_vm.order}},[(_vm.order)?_c('div',{staticClass:"d-flex"},[_c('div',[_c('router-link',{attrs:{"to":{ name: 'execution-single', params: { orderId: _vm.order.auftragID.toString() } }}},[_vm._v(" "+_vm._s(_vm.order.hak.tp)+" ")])],1),_c('div',{staticClass:"ml-auto font-weight-bold"},[_vm._v(" "+_vm._s(_vm.order.hak.address)+" "),(false)?_c('div',[_vm._v(_vm._s(_vm.order.hak.postalCode)+" "+_vm._s(_vm.order.hak.city))]):_vm._e()])]):_vm._e(),(_vm.devicePool && _vm.order)?_c('div',{staticClass:"d-flex"},[_c('div',[_c('router-link',{attrs:{"to":{
                        name: 'execution-geraetestandort',
                        params: {
                            orderId: this.$route.params.orderId,
                            devicePoolId: this.$route.params.devicePoolId,
                        },
                    }}},[_vm._v(" "+_vm._s(_vm.devicePool.position)+" "),(_vm.devicePool.positionDetail && _vm.devicePool.positionDetail.length)?_c('span',[_vm._v(" - "+_vm._s(_vm.devicePool.positionDetail)+" ")]):_vm._e(),(_vm.devicePool.positionDescription)?_c('span',[_vm._v(", "),_c('br'),_vm._v(" "+_vm._s(_vm.devicePool.positionDescription))]):_vm._e()])],1),(_vm.order.hak.address !== _vm.devicePool.address)?_c('div',{staticClass:"ml-auto font-weight-bold"},[_vm._v(" "+_vm._s(_vm.devicePool.address)+" ")]):_vm._e()]):_vm._e()]),_c('router-view',{attrs:{"devicePool":_vm.devicePool,"order":_vm.order}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }